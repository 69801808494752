const { PROCESSING, PREFERRED } = require('./constants');

/**
 * Sets the status message and do some UI changes
 * in `Status` column on the dispatcher dashboard page
 * Also, display message for tooltip timer
 * @param {String} str
 * @return {void}
 */

const setDisplay = (rideRow, status) => {
  const str = status.querySelector('.status-message').innerText;
  if (str === PROCESSING) {
    status.classList.add('-processing');
    if (!status.querySelector('.loader-spin').attributes.style) {
      status.querySelector('.loader-spin').style.display = 'block';
    }
  } else {
    status.classList.remove('-processing');
    if (status.querySelector('.loader-spin').attributes.style) {
      status.querySelector('.loader-spin').removeAttribute('style');
    }
  }

  if (str === PREFERRED) {
    status.classList.add('-aqua', 'tooltip');

    if (!status.querySelector('.preferred-provider-tooltip').attributes.style) {
      status.querySelector('.preferred-provider-tooltip').style.display = 'block';
    }
    if (!status.querySelector('.countdown').attributes.style) {
      status.querySelector('.countdown').style.display = 'block';
    }
  } else {
    status.classList.remove('-aqua', 'tooltip');
    if (status.querySelector('.preferred-provider-tooltip').attributes.style) {
      status.querySelector('.preferred-provider-tooltip').removeAttribute('style');
    }
    if (status.querySelector('.countdown').attributes.style) {
      status.querySelector('.countdown').removeAttribute('style');
    }
  }
};

module.exports = setDisplay;
