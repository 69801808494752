const moment = require('moment');
const setDisplay = require('./setDisplay');

const timerText = (seconds) => `${Math.floor(seconds / 60)}min ${seconds % 60}s`;

const remainingTimeCalculator = (cutOffTime) => {
  if (moment().add(1, 's').isAfter(cutOffTime)) return;

  const remainingTime = cutOffTime.diff(moment(), 's');
  return timerText(remainingTime);
};

const setTimer = (data, status) => {
  const countdownText = status.querySelector('.countdown').querySelector('.countdown-text');
  const cutoffTime = moment.utc(data.ride.timer_start_time).add(data.ride.bidding_window, 's');
  setPpTimer(cutoffTime, countdownText, data);
};

const setPpTimer = (cutoffTime, countdownText, data) => {
  let timeDisplayInterval = null;
  const TIME_INTERVAL = 1000;

  const run = () => {
    const rideRow = document.getElementById(`ride-${data.ride.id}`);
    const status = rideRow.querySelector('[data-component=ride-bid-countdown]');
    const timeLeft = remainingTimeCalculator(cutoffTime);
    if (timeLeft) {
      countdownText.innerHTML = timeLeft;
    } else {
      status.querySelector('.status-message').innerText = 'Processing';
      setDisplay(rideRow, status);
      clearInterval(timeDisplayInterval);
    }
  };

  run();
  timeDisplayInterval = setInterval(run, TIME_INTERVAL);
};

module.exports = setTimer;
