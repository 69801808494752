import createCableSubscription from '../utils/createCableSubscription';
import insertHtml from '../utils/insertHtml';
import setDisplay from '../utils/setDisplay';
import setRideCountHeader from '../utils/setRideCountHeader';
import setTimer from '../utils/setTimer';

const COMMUNITY_PATH_NAME = '/dispatcher/community';
const PREFERRED_STATUS = 'Preferred';

// evaluate on use since these tables are not always present
const dispatcherCommunityRidesTable = () =>
  document.getElementById('dispatcher-community-rides-table');
const reactCommunityRidesTable = () =>
  document.getElementById('dispatcher-community-rides-table-react');

const handleUpdatedRideBroadcast = (data, ride) => {
  // Remove element
  ride.remove();
  insertHtml.prepend(dispatcherCommunityRidesTable(), data.table_row);
};

const handleNewRideBroadcast = (data) => {
  /**
   * If the community table is empty we have to first replace the empty div with the table
   * Then we can prepend rides to the table & update the ride count header
   */
  setRideCountHeader();
  const noRidesPlaceholder = document.getElementById('no-rides-placeholder');
  const mainCommunityContainer = document.getElementById(
    'main-community-container',
  );
  // evaluate on use since it will not always be there
  if (noRidesPlaceholder) {
    noRidesPlaceholder.remove();

    if (!mainCommunityContainer) {
      return;
    }

    mainCommunityContainer
      .querySelector('#patient-rides-table')
      .classList.remove('hide');
    insertHtml.prepend(dispatcherCommunityRidesTable(), data.table_row);
  } else {
    insertHtml.prepend(dispatcherCommunityRidesTable(), data.table_row);
  }
};

document.addEventListener('turbolinks:load', () => {
  if (reactCommunityRidesTable()) {
    return;
  }

  createCableSubscription(
    COMMUNITY_PATH_NAME,
    'RidesChannel',
    'dispatcherCommunityRides',
    {
      connected() {
        console.log('Connected Community Rides');
      },

      disconnected() {
        console.log('Disconnected Community Rides');
      },

      received(data) {
        if (reactCommunityRidesTable()) {
          return;
        }

        if (!dispatcherCommunityRidesTable() || data?.ride?.id) {
          return;
        }

        /**
         * Two types of broadcast
         * A new ride broadcast
         * An updated ride broadcast
         * These can be noted by looking for the ride in the table
         */
        const rideRow = () => document.getElementById(`ride-${data.ride.id}`);

        // eslint-disable-next-line no-unused-expressions
        rideRow()
          ? handleUpdatedRideBroadcast(data, rideRow())
          : handleNewRideBroadcast(data);

        if (!rideRow()) {
          return;
        }

        const statusBadge = rideRow().querySelector(
          '[data-component=ride-bid-countdown]',
        );
        if (statusBadge.innerText === PREFERRED_STATUS) {
          setDisplay(rideRow(), statusBadge);
          setTimer(data, statusBadge);
        }
      },
    },
  );
});
